import React, { useState } from 'react';
import { Drawer, Button, Select, MenuItem, TextField, FormControl, InputLabel, Box, IconButton, Tab, Tabs, Typography, type Theme, useMediaQuery, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ApprovalActions from './approvalActions';
import { ApprovalChain } from 'modules/cbre-approval-chain-widget';
import { ApprovalsHistory } from 'modules/cbre-approval-history';
import ApprovalDetailsView from './ApprovalDetails';
import { useApprovalsContext } from 'modules/cbre-approvals-widget';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../styles/approvalRequestDetails.scss";
import ProgressStepper from './requestProgress';

type RequestDetailsViewDrawerProps = {
    open: boolean
    setIsOpen: (open: boolean) => void
    requestId: string | null
};

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, height: "100%" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const TabContainer: React.FC<any> = ({ approvalInfo }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="approvals-request-bar">
            <Tabs value={value} onChange={handleChange} aria-label="approval tabs" sx={{ minHeight: "auto" }} TabIndicatorProps={{
                sx: {
                    // display: 'none', // Hide the default indicator
                },
            }}>
                <Tab className='approval-request-container-tab-item' label="Request" />
                <Tab className='approval-request-container-tab-item' label="Approval Chain" />
                <Tab className='approval-request-container-tab-item' label="Approval History" />
            </Tabs>
            <TabPanel value={value} index={0} className="approval-request-detail-tab-panel">
                <ApprovalDetailsView approvalDetails={approvalInfo} mode="large" />
            </TabPanel>
            <TabPanel value={value} index={1} className="approval-request-detail-tab-panel">
                <ApprovalChain approvals={8} totalApprovals={12} />
            </TabPanel>
            <TabPanel value={value} index={2} className="approval-request-detail-tab-panel">
                <ApprovalsHistory approvals={approvalInfo} />
            </TabPanel>
        </div>
    );
};

const RequestDetailsViewDrawer: React.FC<RequestDetailsViewDrawerProps> = ({ open, setIsOpen, requestId }) => {

    const { config } = useApprovalsContext()!
    const { baseUrl, approvalDetails } = config;
    const { approvalDetailLarge, getApprovalDetails } = approvalDetails;
    const [approvalInfo, setApprovalInfo] = useState<any>(null);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));


    return (
        <div>
            <Drawer anchor="right" open={open} onClose={() => setIsOpen(false)} ModalProps={{ keepMounted: false }} style={{ zIndex: 1250 }} sx={{ width: "100%" }} PaperProps={{
                sx: {
                    width: '100%',
                    // backgroundColor:  isDesktop ? '#f9f9f9': "inherit",
                    // padding: 0,
                    // margin: "15px",
                },
            }} >
                {approvalDetailLarge && <Box
                    sx={{ padding: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
                    role="presentation"
                    className="approval-request-details-container"
                >
                    <div className='d-flex approval-request-header'>
                        <div className='d-flex align-items-center'>
                            {!isDesktop && <IconButton onClick={() => { setIsOpen(false) }}>
                                <ArrowBackIcon />
                            </IconButton>}
                            <Typography variant="h5">{approvalDetailLarge.heading}</Typography>
                            {/* {additionalDetails.wr_number?.value && <Typography variant="subtitle1">SR# {additionalDetails.wr_number?.value}</Typography>}
                            {additionalDetails.c_compid?.value && <ul><li><Typography variant="subtitle1">{additionalDetails.c_compid?.value}</Typography></li></ul>} */}
                        </div>
                        <div className='d-flex align-items-center'>
                            <ApprovalActions />
                            {isDesktop && <IconButton onClick={() => { setIsOpen(false) }}>
                                <CloseIcon />
                            </IconButton>}

                        </div>
                    </div>
                    {isDesktop ? <div className='desktop-request-detail-container'>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Box >
                                    <TabContainer approvalInfo={approvalDetailLarge} />

                                </Box>
                            </Grid>
                            <Grid item xs={3} sx={{ marginTop: 7, backgroundColor: "#F5F7F7", paddingRight: 2 }} >
                                <Box style={{ height: "100%", paddingBottom: 16 }}>
                                    <ProgressStepper />
                                </Box>
                            </Grid>
                        </Grid>
                    </div> : <TabContainer approvalInfo={approvalDetailLarge} />}

                </Box>}
            </Drawer>
        </div>
    );
};

export default RequestDetailsViewDrawer;