import { type ReturnUseApprovalsListProps, type IApprovalTab, type ReturnUseApprovalsListApiProps, type UseApprovalsListApiProps, type QueryParamsProps } from 'modules/cbre-approvals-widget/types';
import { useState, useEffect, useRef } from 'react';
import service from 'util/apiService';

const useApprovalsListApi = <T>({
  url: baseUrl,
  queryParams = {},
  payload = null,
}: UseApprovalsListApiProps<T>): ReturnUseApprovalsListApiProps<T> => {
  const [approvals, setApprovals] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const resolvePending  = useRef<{resolve: null | ((value: ReturnUseApprovalsListProps | PromiseLike<ReturnUseApprovalsListProps>) => void)}>({resolve: null}).current;
  const renderMethod = useRef({method : null}).current
  const [approvalMethod, setApprovalMethod] = useState<IApprovalTab["tab"]["value"]>("myApprovals");
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [url, setUrl] = useState<string>(() => {
    // Ensure the baseUrl ends with a '/'
    return baseUrl.endsWith('/')
      ? `${baseUrl}approvals`
      : `${baseUrl}/approvals`;
  });
  const [params, setQueryParams] = useState<Partial<QueryParamsProps>>(() => {
    if (!queryParams.pageSize)
      queryParams.pageSize = 10;
    if (!queryParams.pageNumber)
      queryParams.pageNumber = 1;
    return queryParams;
  });
  const [body, setPayload] = useState<T | null>(payload);
  const hasMounted = useRef({isInitial: true}).current;
  const [pageIndex, setPageIndex] = useState((queryParams?.pageNumber || 1) - 1);
  const [pageSize] = useState(queryParams.pageSize || 10);

 
  
  const fetchMyApprovals = async (withExistenceData?: boolean) => {
    try {
      const url = baseUrl.endsWith('/')
      ? `${baseUrl}approvals`
      : `${baseUrl}/approvals`;
      const queryString = new URLSearchParams(
        Object.entries(params).reduce((acc, [key, value]) => {
          acc[key] = value?.toString();
          return acc;
        }, {} as Record<string, string>)
      ).toString();
      const fetchUrl = queryString ? `${url}?${queryString}` : url;
      // const payload = body ? JSON.stringify(body) : null;
      !withExistenceData && setLoading(true)
      const response = await service.get(fetchUrl);
      setTotalPageCount(response.data.pageCount)
      setApprovals((prev) => {
        if(withExistenceData) return [...prev, ...response.data?.recordsList || []];
        return response.data?.recordsList || []
    });
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }
  };

  const fetchExternalApprovals = async (withExistenceData?: boolean) => {
    try {
      const url = baseUrl.endsWith('/')
      ? `${baseUrl}approvals/external-approval`
      : `${baseUrl}/approvals/external-approval`;
      const queryString = new URLSearchParams(
        Object.entries(params).reduce((acc, [key, value]) => {
          acc[key] = value?.toString();
          return acc;
        }, {} as Record<string, string>)
      ).toString();
      const fetchUrl = queryString ? `${url}?${queryString}` : url;
      // const payload = body ? JSON.stringify(body) : null;
      !withExistenceData && setLoading(true)
      const response = await service.get(fetchUrl);
      setTotalPageCount(response.data.pageCount)
      setApprovals((prev) => {
        if(withExistenceData) return [...prev, ...response.data?.recordsList || []];
        return response.data?.recordsList || []
    });
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }
  };

  const fetchApprovalsHistory = async (withExistenceData?: boolean) => {
    try {
      const url = baseUrl.endsWith('/')
      ? `${baseUrl}approvals`
      : `${baseUrl}/approvals`;
      const queryString = new URLSearchParams(
        Object.entries(params).reduce((acc, [key, value]) => {
          acc[key] = value?.toString();
          return acc;
        }, {} as Record<string, string>)
      ).toString();
      const fetchUrl = queryString ? `${url}?${queryString}` : url;
      // const payload = body ? JSON.stringify(body) : null;
      !withExistenceData && setLoading(true)
      const response = await service.get(fetchUrl);
      setTotalPageCount(response.data.pageCount)
      setApprovals((prev) => {
        if(withExistenceData) return [...prev, ...response.data?.recordsList || []];
        return response.data?.recordsList || []
    });
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }
  };



  const fetchApprovals = async (approvalType?: IApprovalTab["tab"]["value"], withExistenceData?: boolean ) => {
    approvalType = approvalType || approvalMethod;
    try {
      if(approvalType){
        if(approvalType === 'myApprovals') {
           fetchMyApprovals(withExistenceData)
        }
        if(approvalType === 'externalApprovals') {
           fetchExternalApprovals(withExistenceData)
        }
        if(approvalType === 'approvalsHistory') {
          fetchApprovalsHistory(withExistenceData)
        }
      }
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePendingChanges = (): Promise<ReturnUseApprovalsListProps> => {
    return new Promise<ReturnUseApprovalsListProps>((resolve) => {
      resolvePending.resolve = resolve;
    });
  }


  const returnType = {
    approvals,
    url,
    pageSize,
    loading,
    params,
    totalPageCount,
    initialQueryParams: queryParams,
    setUrl,
    setQueryParams,
    setPayload,
    setPageIndex,
    setApprovals,
    fetchApprovals,
    fetchMyApprovals,
    fetchExternalApprovals,
    fetchApprovalsHistory,
    setApprovalMethod,
    handlePendingChanges
  }

  // useEffect(()=>{
  //   if(hasMounted.isInitial) {hasMounted.isInitial = false; return;}
  //   fetchApprovals()
  // }, [pageIndex])

  useEffect(()=>{
    const config = {...returnType, handlePendingChanges: undefined}
    resolvePending?.resolve && resolvePending.resolve({ ...config });
  }, [approvalMethod, pageIndex, pageSize, url, params, body])

  return returnType
};

export default useApprovalsListApi;
