const mockResponse = {
    factObjectSubState: {
      id: "272cf45a-db1c-4ed0-ac25-6a8164ade0b2",
      factId: "4a0f503c-9000-4e28-97ca-bc554cb52dfb",
      type: "AUTHORIZATION",
      name: "Approve client limit",
      chains: [
        {
          id: "3255ca51-89ea-48a5-81a0-6049c4acfb39",
          majorVersion: 1,
          minorVersion: 1,
          createdDate: "2024-02-01T11:26:02Z",
          updatedTime: "2024-02-01T11:26:02Z",
          source: "Request",
          chainDefinitionId: "3a6d2efe-eaab-498f-b917-083ac9607d51",
          chainStatus: "IN_PROGRESS",
          limitId: "8ed7cf63-3232-446f-8cc9-9810f47a2a30",
          limitValue: 1000,
          unit: "USD",
          approvers: [
            {
                id: "29af6774-9c14-41a0-a783-78c84706970f",
                personId: "5b1adc0f-83e7-4e4f-b2df-6be2591c4359",
                personType: "Employee",
                group: 2,
                sequence: 1,
                generalization: {
                  id: "beb24efd-3e3f-49d1-8762-594354560ccc",
                  name: null,
                  group: "2"
                },
                authScopeId: "bd8cb624-f5f6-4474-958e-c41980f36a97",
                when: "After Previous",
                responseCount: 1,
                approvalRule: null,
                decision: {
                  status: null,
                  decisionDate: null,
                  madeByDelegate: null,
                  decisionNote: null
                }
              },
            {
              id: "7ed211f1-40ad-4e68-8b46-1355f91205e8",
              personId: "a5b3858f-e8ef-4c83-a285-1d19a2ac6cdb",
              personType: "Employee",
              group: 1,
              sequence: 2,
              generalization: {
                id: "a364ea55-03a8-40ad-bfc2-11567e71f2cd",
                name: null,
                group: "1"
              },
              authScopeId: "f18177ea-2a3a-480a-9cc9-8383c657d74a",
              when: "On Start",
              responseCount: 1,
              approvalRule: null,
              decision: {
                status: null,
                decisionDate: null,
                madeByDelegate: null,
                decisionNote: null
              }
            },
            {
              id: "f2b3ad6c-3e03-4e8a-88ba-1272e20eebb9",
              personId: "a61de443-5756-4b60-bf8b-c928eb4ef943",
              personType: "Employee",
              group: 1,
              sequence: 1,
              generalization: {
                id: "a364ea55-03a8-40ad-bfc2-11567e71f2cd",
                name: null,
                group: "1"
              },
              authScopeId: "f18177ea-2a3a-480a-9cc9-8383c657d74a",
              when: "On Start",
              responseCount: 1,
              approvalRule: null,
              decision: {
                status: null,
                decisionDate: null,
                madeByDelegate: null,
                decisionNote: null
              }
            },

            {
                id: "742e82e5-b48d-4c2f-bd5b-ed18a957e7d9",
                personId: null,
                personType: "Employee",
                group: 4,
                sequence: 1,
                generalization: {
                  id: "3a1b0ba9-39ad-4cd4-8d77-b6af08968bd1",
                  name: null,
                  group: "4"
                },
                authScopeId: null,
                when: "With Previous",
                responseCount: 2,
                approvalRule: null,
                decision: {
                  status: null,
                  decisionDate: null,
                  madeByDelegate: null,
                  decisionNote: null
                }
              },
           
            {
              id: "dc749137-e8a1-4742-ba24-efb075eabf14",
              personId: "daab6bda-5d29-438c-a756-7426c04bd020",
              personType: "Employee",
              group: 3,
              sequence: 1,
              generalization: {
                id: "e8e548f2-0400-414b-896a-77b59b7060a6",
                name: null,
                group: "3"
              },
              authScopeId: "15809a7b-c59d-4e1f-92ca-a9d18591474e",
              when: "With Previous",
              responseCount: 1,
              approvalRule: null,
              decision: {
                status: null,
                decisionDate: null,
                madeByDelegate: null,
                decisionNote: null
              }
            },
            
          ],
          delegates: null,
          excluded: null
        }
      ],
      status: [
        {
          id: null,
          status: "ACTIVE",
          statusDate: "2024-02-01T11:26:02"
        }
      ],
      ttl: "2023-03-22T16:08:54Z"
    }
  };
  
  export default mockResponse;