import React from 'react';
import { CheckCircle, PendingActions, Person } from '@mui/icons-material';
import '../styles/ApprovalChain.scss';
import ApprovalChainLink from './ApprovalChainLink';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import useApprovalChain from './utils';

interface ApprovalChainProps {
  approvals: number;
  totalApprovals: number;
}

const ApprovalChain: React.FC<ApprovalChainProps> = ({ approvals = 0, totalApprovals = 0 }) => {

  const  { groupedApprovers } = useApprovalChain();

  return (
    <div className="approvals-chain-container">
      <div className="title-wrap">
        <div className="lv-set">
          <div className="label">Approvals</div>
          <div className="value">{approvals} out of {totalApprovals}</div>
        </div>
      </div>
      <div className="approvals-wrap">
        <ApprovalChainLink
          name="Barney Rubble"
          status="Approved on 9/26/24 9:35 PM"
          icon={<CheckCircle style={{color: '#50AF78'}}/>}
          approvers={[
            {
              name: "Barney Rubble",
              status: "Approved on 9/27/24 9:35 PM",
              icon: <CheckCircle style={{color: '#50AF78'}}/>,
            }
          ]}
        />
        <ApprovalChainLink
          name="Will Smith"
          status="-"
          icon={<Person />}
          approvers={[
            {
              name: "Wilma Flintstone",
              status: "Approved on 9/27/24 9:35 PM",
              icon: <CheckCircle style={{color: '#50AF78'}}/>,
              type: "OR"
            }
          ]}
        />
        <ApprovalChainLink
          name="Bill Smith"
          status="Pending Approval"
          icon={<PendingActions />}
          approvers={[
            {
              name: "Joe Rockhead",
              status: "Pending Approval",
              icon: <CircleOutlinedIcon style={{color: '#FF9742'}}/>
            },
            {
              name: "Pebbles Flintstone",
              status: "Pending Approval",
              icon: <CircleOutlinedIcon style={{color: '#FF9742'}}/>
            }
          ]}
        />
        <ApprovalChainLink
          name="Tim Apple"
          status="-"
          icon={<Person />}
        />
        <ApprovalChainLink
          name="Arnold Perez"
          status="-"
          icon={<Person />}
        />
      </div>
    </div>
  );
};

export default ApprovalChain;