/* eslint-disable react/jsx-props-no-spreading,react/jsx-filename-extension */
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomScrollbars = (props) => (
  <Scrollbars
    {...props}
    autoHide={true}
    renderTrackHorizontal={(currentProps) => (
      <div
        {...currentProps}
        style={{ display: 'none' }}
        className="track-horizontal"
      />
    )}
  />
);

export default CustomScrollbars;
