import React, { useEffect, useState } from 'react';
import HistoryItem from './historyItem';
import '../styles/ApprovalsBar.scss';

interface IhistoryItem {
    status: string;
    date: string;
    author: string;
    role: string;
    email: string;
    comments?: string;  
}

interface ApprovalHistoryProps {
    approvals?: number;
    totalApprovals?: number;
  }

const ApprovalsHistory: React.FC<ApprovalHistoryProps> = ({ approvals }) => {
  const [historyItems, setHistoryItems] = useState<IhistoryItem[]>([])

  useEffect(()=>{
    const historyItems:IhistoryItem[] = [
      {
        status: 'Approved',
        date: '9/26/24 9:35 PM',
        author: 'Barney Rubble',
        role: 'Manager',
        email: '',
        comments: 'Approved'
      },
      {
        status: 'Approved',
        date: '9/27/24 9:35 PM',
        author: 'Wilma Flintstone',
        role: 'Manager',
        email: '',
        comments: 'Approved'
      },
      {
        status: 'Approved',
        date: '9/27/24 9:35 PM',
        author: 'Wilma Flintstone',
        role: 'Manager',
        email: '',
        comments: 'Approved'
      },
      {
        status: 'Pending Approval',
        date: '9/27/24 9:35 PM',
        author: 'Joe Rockhead',
        role: 'Manager',
        email: '',
        comments: ''
      }
    ]
      setHistoryItems(historyItems)
  }, [])


  return (
    <div className="approval-history-container">
      <div className="history-wrap">
        {historyItems.length !== 0 && historyItems.map((item, index) => (
          <HistoryItem key={index} roleInfo={item.role} {...item} />
        ))}
      </div>
    </div>
  );
};

export default ApprovalsHistory;