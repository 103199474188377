import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
//import variables from 'pjm-dls/dist/styles/base/_variables.scss';
 import variables from '../../styles/base/_variables.scss';
 import { createTheme, } from '@mui/material/styles';
import { color } from '@mui/system';

 const theme = createTheme();
export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 0,
      tablet: 600,
      laptop: 960,
      desktop: 1280,
    },
  },
  palette: {
    primary: {
      light: green[300],
      main: variables.primary,
      dark: '#006a4d',
      contrastText: variables.white,
    },
    secondary: {
      light: red[300],
      main: red['400'],
      dark: red[700],
      contrastText: variables.white,
    },
    light: theme.palette.augmentColor({
      color: {
        main: '#003f2d14',
        contrastText: variables.primary,
      },
     }),
    
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: 'Calibre',
    button: {
      fontWeight: 500,
      fontSize: '0.79rem',
      textAlign: 'inherit',
      textTransform: 'capitalize',
      borderRadius: '4px',
    },
  },
  overrides: {
    MuiPopover: {
      root: {
        zIndex: '1600 !important',
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none !important',
        minWidth: '100px !important',
        fontSize: '0.9rem !important',
        height: '36px !important',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        padding: '25px !important',
      },
      paper: {
        borderTopColor: 'rgb(0, 159, 111)',
        borderTop: 'solid',
        borderTopWidth: '5px',
      },
    },
    MuiInputLabel: {
      outlined: {
        '&$focused': {
          color: variables.primary,
        },
      },
    },
    MuiStepper: {
      root: {
        padding: '5px !important',
      },
    },
    MuiAccordion: {
      root: {
        borderRadius: '4px !important',
        boxShadow: '0 0 5px 0 var(--shadow,rgba(170,183,178,.6))',
      },
    },
  },
};
