import { Search } from '@mui/icons-material';
import { Alert, Autocomplete, TextField, autocompleteClasses } from '@mui/material';
import AsyncAutocompleteBoxComponent from 'components/AsyncAutocompleteBoxComponent';
import RenderInput from 'components/renderInput';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import service from 'util/apiService';
import {
    clearSiteLocationAlerts,
    getDefaultSite,
    getSiteInfo,
    setSiteInfo,
    setSiteInfoSelected,
    setSiteInfoSuccess
} from '../ducks/actions';
import "../styles/site-location.scss";
import { getSiteInfoUrl, setSiteInfoUrl } from '../ducks/selectors';
import { RootState } from 'modules/cbre-flow-Common/reducers';
import Alerts from 'components/Alerts/Index';
import Button from "@mui/material/Button";
import {getUserConfig, getUserInfoUrl} from "modules/cbre-flow-api-ducks";

const moduleName = 'cbre-flow-site-location'
interface LocationSelectionProps {
   isInitialSiteSelection: boolean;
   closeSiteSelection?:any
}
interface SiteInfo {
    siteName: string;
    siteId: number;
    [key: string]: any;
}

const LocationSelection: React.FC<LocationSelectionProps> = (props) => {
    // Your component code here
    const methods = useForm();
    const [options, setOptions] = useState<any[]>([]);
    const [text, setText] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedSite, setSelectedSite] = useState({})
    const dispatch = useDispatch();
    const history = useHistory();
    const siteUrl = useSelector(getSiteInfoUrl);
    const userUrl = useSelector(getUserInfoUrl);
    const updateSiteUrl = useSelector(setSiteInfoUrl);
    const { defaultSiteLists, alert } = useSelector((state: RootState) => state["cbre-flow-site-location"] as unknown as { defaultSiteLists: any, alert: any })
    const loadOptions = (e: React.SyntheticEvent, value: string, mode: any) => {
        if (mode === "input") {
            service.get(`${siteUrl}?searchKey=${encodeURIComponent(value)}&pageSize=20&pageNumber=1`).then(({ data }) => {
                if (data?.recordsList) setOptions(data.recordsList)
                else setOptions([])
                setText(value);
            })
        }

    }

    useEffect(() => {
        if (defaultSiteLists && !defaultSiteLists?.length) {
            dispatch(getDefaultSite({ apiUrl: `${siteUrl}?pageSize=20&pageNumber=1` }))
        }
    }, [JSON.stringify(defaultSiteLists)])

    useEffect(() => {
        dispatch(getUserConfig({ apiUrl: `${userUrl}` }, setSiteInfoSelected))
    }, []);

    return (
        <div className={!props?.isInitialSiteSelection ? 'p-3' : ''}>
            {/* <Alerts alertState={alert?.type} handleRequestClose={handleRequestClose} message={alert?.message}/> */}

            <Autocomplete
                id="combo-box-demo"
                className='site-selection-search'
                options={text ? options : defaultSiteLists}

                getOptionLabel={(option: any) => option?.siteName}
                sx={{
                    width: '100%',
                    minWidth: 350,
                    [`& .${autocompleteClasses.popupIndicator}`]: {
                        transform: "none"
                    }
                }}
                onInputChange={loadOptions}
                onChange={async (e, value, mode) => {
                    if (mode === "selectOption" && !props?.isInitialSiteSelection) {
                        setSelectedSite(value);
                        const resp:any = await dispatch(setSiteInfo({apiUrl: `${updateSiteUrl}${value.siteId}?appCode=ELIS`, data: value}));
                        if(resp?.statusCode === 200 && typeof props.closeSiteSelection === "function") props.closeSiteSelection();
                        dispatch(getUserConfig({ apiUrl: `${userUrl}` }))
                    } else if (mode === "selectOption" && props?.isInitialSiteSelection) {
                        setSelectedSite(value);
                        dispatch(setSiteInfoSelected(value))
                    } else if (mode !== "selectOption" && props?.isInitialSiteSelection) {
                        setSelectedSite(value);
                        dispatch(setSiteInfoSelected(''))
                    }
                    setText('')
                }}
                renderInput={(params) => <TextField {...params}
                                                    label={<IntlMessages id="header.siteSelectionLabel"/>}/>}
                popupIcon={<Search/>}
                />
                {props?.isInitialSiteSelection && (
                    <div className="continueButtonContainer">
                        <Button
                            variant="contained"
                            color="primary"
                            className={((selectedSite as SiteInfo)?.siteName && !loading) ? "active" : "disabled"}
                            disabled={(!((selectedSite as SiteInfo)?.siteName) || loading)}
                            onClick={() => {
                                setLoading(true);
                                if ((selectedSite as SiteInfo)?.siteName) {
                                    dispatch(setSiteInfo({apiUrl: `${updateSiteUrl}${(selectedSite as SiteInfo)?.siteId}?appCode=ELIS`, userUrl , redirectUrl:'/elis/dashboard', history, callback: setLoading, data: selectedSite}))
                                    //history.push('/elis/dashboard');
                                }
                            }}
                        >
                        Continue
                        </Button>
                    </div>
                )}
        </div>
    );
};

export default LocationSelection;