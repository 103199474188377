import SelectBox from 'components/SelectBox';
import React, { ReactNode } from 'react';
import AsyncAutocompleteBoxComponent from './AsyncAutocompleteBoxComponent';
import ControlledTextBox from './ControlledTextBox';
import DateSelectorCustomDisplay from './DateSelectorCustomDisplay';
import ControlledCurrencyTextBox from './ControlledCurrencyTextBox';
import ControlledTextArea from './ControlledTextArea';


type SelectBoxProps = {
    id: string;
    type: "select";
    name: string;
    className?: string;
    methods: any;
    label?: React.ReactNode;
    data: any[];
    menuItemId: string;
    menuItemDisplayName?: string;
    defaultValue?: string;
    focusField?: string;
    rules: any;
    errorMessages: { [key: string]: any };
    module: string;
    disabled?: boolean;
    menuItemValue?: string | undefined;
    displayNameHasId?: boolean | undefined;
    displayNameIdField?: string | undefined;
    menuItemDescription?: string | undefined;
    disableRequireHighlight?: boolean | undefined;
    disableEmptyMenuItem?: boolean | undefined;
    addedMenuProps?: any;
    mode?: string | undefined;
    FormHText?: string | undefined;
    menuItemCount?: string | undefined;
    intlValues?: any;
    addNewOptionsSaveUrl?: string;
    addNewOptions?: boolean;
};

type AsyncAutoComplete = {
    id: string;
    type: "asyncAutoComplete";
    name: string;
    className?: string;
    methods: any;
    label?: React.ReactNode;
    loadOptions: (value: any, cb: any) => void;
    defaultOptions: any[];
    defaultValue?: any;
    focusField?: string;
    rules: any;
    errorMessages: { [key: string]: any };
    module: string;
    disabled?: boolean;
    menuItemValue?: string | undefined;
    menuItemDescription?: string | undefined;

    getOptionValue: (data: any) => any;
    getOptionLabel?: (data: any, context:any) => any;
    formatOptionLabel?: (data: any, context:any) => any;
    onMenuClose?: (data?: any) => any;
    onMenuOpen?: (data?: any) => any;
    isSearchable?: boolean;
    showSearchIcon?: boolean;
    InputProps?: any;
    menuPortalTarget?: any;
    isShrink?: boolean;
    showDropDown?: boolean;
    isMulti?: boolean;
    value?: any;
    onChange?: any;
    isClearable?: boolean;
    FormHText?: any;
    intlValues?: any;
    addNewOptions?: {
        enabled: boolean;
        mode: string;
        body?: any;
        cb?: (data?: any) => void;
    };
    menuIsOpen?:boolean;
    closeMenuOnSelect?:boolean;
    mode?: string;
    validateChange?: (value: any) => Promise<boolean>;
};

interface ControlledTextBoxProps {
    type: "text";
    id?: string;
    className?: string;
    errorMessages: { [key: string]: any };
    disabled?: boolean;
    focusField?: string;
    label?: React.ReactNode;
    name: string;
    inputProps?: any;
    rules?: any;
    defaultValue?: any;
    intlValues?: any; // Replace 'any' with the actual type if known
    module: string;
    patternValidationProps?: any;
    variant?: string | undefined;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    mode?: string;
    methods: any;
    [key: string]: any;
}
interface ControlledTextAreaProps {
    type: "textArea";
    id?: string;
    className?: string;
    errorMessages: { [key: string]: any };
    disabled?: boolean;
    focusField?: string;
    label?: React.ReactNode;
    name: string;
    inputProps?: any;
    rules?: any;
    defaultValue?: any;
    intlValues?: any; // Replace 'any' with the actual type if known
    module: string;
    patternValidationProps?: any;
    variant?: string | undefined;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    mode?: string;
    methods: any;
    placeholder?:ReactNode,
    inputRef?:any,
    minRows?:number,
    maxRows?:number,
    maxLength?:number,
}
interface ControlledCostBoxProps {
    type: "currency";
    id?: string;
    className?: string;
    errorMessages: { [key: string]: any };
    disabled?: boolean;
    focusField?: string;
    label?: React.ReactNode;
    name: string;
    inputProps?: any;
    rules?: any;
    defaultValue?: any;
    intlValues?: any; // Replace 'any' with the actual type if known
    module: string;
    patternValidationProps?: any;
    variant?: string | undefined;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    mode?: string;
    methods: any;
    decimalScale?: number;
    prefix?: string;
}
interface ControlledNumberBoxProps {
    type: "number";
    id?: string;
    className?: string;
    errorMessages: { [key: string]: any };
    disabled?: boolean;
    focusField?: string;
    label?: React.ReactNode;
    name: string;
    inputProps?: any;
    rules?: any;
    defaultValue?: any;
    intlValues?: any; // Replace 'any' with the actual type if known
    module: string;
    patternValidationProps?: any;
    variant?: string | undefined;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    mode?: string;
    methods: any;
    decimalScale?: number;
    prefix?: string;
}

type DateSelectorCustomDisplayProps = {
    type: "date";
    format?: string;
    onFocusDateFormat?: string;
    onBlurChangeDateFormat?: boolean;
    onBlurDateFormat?: string;
    id: string;
    name: string;
    focusField?: any;
    views?: string[];
    rules: any;
    disabled?: boolean;
    checkInternalErrors?: (data?: any) => void;
    errorMessages: any;
    module: string;
    label?: React.ReactNode;
    defaultValue?: any;
    methods: any;
    className?: any
    placeholder?: string;
    intlValues?: any;
    onChange?: any;
    openTo?: "day" | "month" | "year"
    minDate?: any;
};



type Props = SelectBoxProps | AsyncAutoComplete | ControlledTextBoxProps | DateSelectorCustomDisplayProps | ControlledCostBoxProps | ControlledTextAreaProps | ControlledNumberBoxProps;

const RenderInput = (props: Props) => {
    const { type, id, name, defaultValue,
        rules, focusField, label, className, methods, errorMessages, module, disabled, intlValues
    } = props;
    const { setValue, getValues, control, formState: { errors }, register } = methods;
    switch (type) {
        case 'select':
            {
                const { menuItemId, data, addedMenuProps, mode, menuItemCount, menuItemDescription, menuItemDisplayName,
                    menuItemValue, disableEmptyMenuItem, displayNameHasId, displayNameIdField, FormHText,
                    disableRequireHighlight, addNewOptionsSaveUrl, addNewOptions
                } = props;
                return (
                    <SelectBox
                        id={id || name}
                        name={name}
                        className={className}
                        label={label}
                        control={control}
                        data={data}
                        menuItemId={menuItemId}
                        menuItemDisplayName={menuItemDisplayName}
                        defaultVal={defaultValue}
                        setValue={setValue}
                        getValues={getValues}
                        focusField={focusField}
                        rules={rules}
                        errors={errors[name]?.type}
                        errorMessages={errorMessages}
                        module={module}
                        disabled={disabled}
                        menuItemValue={menuItemValue}
                        displayNameHasId={displayNameHasId}
                        displayNameIdField={displayNameIdField}
                        menuItemDescription={menuItemDescription}
                        menuItemCount={menuItemCount}
                        disableRequireHighlight={disableRequireHighlight}
                        disableEmptyMenuItem={disableEmptyMenuItem}
                        addedMenuProps={addedMenuProps}
                        mode={mode}
                        FormHText={FormHText}
                        intlValues={intlValues}
                        addNewOptionsSaveUrl={addNewOptionsSaveUrl}
                        addNewOptions={addNewOptions}

                    />
                );
            }
        case 'asyncAutoComplete':
            {
                const { loadOptions, addNewOptions, closeMenuOnSelect, validateChange, menuPortalTarget=document.body, onMenuClose, onMenuOpen, formatOptionLabel, isSearchable, menuIsOpen, FormHText, getOptionLabel, getOptionValue, defaultOptions, showSearchIcon = false, isMulti = false, value, mode, onChange, isClearable } = props;
                return (
                    <AsyncAutocompleteBoxComponent
                        control={control}
                        id={id}
                        name={name}
                        showSearchIcon={showSearchIcon}
                        label={label}
                        module={module}
                        setValue={setValue}
                        getValues={getValues}
                        getOptionValue={getOptionValue}
                        getOptionLabel={getOptionLabel}
                        loadOptions={loadOptions}
                        defaultOptions={defaultOptions}
                        disabled={disabled}
                        errors={errors[name]?.type}
                        errorMessages={errorMessages}
                        defaultVal={defaultValue}
                        InputProps={props.InputProps}
                        isShrink={props.isShrink}
                        showDropDown={props.showDropDown}
                        menuPortalTarget={menuPortalTarget}
                        isMulti={isMulti}
                        value={value}
                        onChange={onChange}
                        isClearable={isClearable}
                        FormHText={FormHText}
                        rules={rules}
                        intlValues={intlValues}
                        addNewOptions={addNewOptions}
                        menuIsOpen={menuIsOpen}
                        isSearchable={isSearchable}
                        closeMenuOnSelect={closeMenuOnSelect}
                        focusField={focusField}
                        formatOptionLabel={formatOptionLabel}
                        mode={mode}
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuClose}
                        validateChange={validateChange}
                    />
                );
            }
        case 'text':
            {
                const { variant, mode, inputProps, onBlur, onChange, intlValues = {}, ...rest } = props;
                return <ControlledTextBox
                    label={label}
                    inputProps={inputProps}
                    rules={rules}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={disabled}
                    mode={mode}
                    focusField={focusField}
                    defaultText={""}
                    variant={variant}
                    errors={(errors as any)[name]?.type ?? ''}
                    intlValues={intlValues}
                    {...rest}
                />;
            }
        case 'textArea':
            {
                const { variant, mode, inputProps, onBlur, onChange, intlValues = {}, maxLength, maxRows, minRows, inputRef, placeholder } = props;
                return <ControlledTextArea
                    label={label}
                    name={name}
                    inputProps={inputProps}
                    rules={rules}
                    control={control}
                    module={module}
                    setValue={setValue}
                    getValues={getValues}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={disabled}
                    mode={mode}
                    focusField={focusField}
                    defaultText={""}
                    variant={variant}
                    errors={(errors as any)[name]?.type ?? ''}
                    errorMessages={errorMessages}
                    intlValues={intlValues}
                    minRows={minRows}
                    maxLength={maxLength}
                    maxRows={maxRows}
                    inputRef={inputRef}
                    defaultValue={defaultValue}
                    placeholder={placeholder}

                />;
            }
        case "date": {
            const { format = "MM/DD/YYYY", views = ['year', 'month', 'day'], openTo="day",  checkInternalErrors = () => { console.log('') }, minDate, onFocusDateFormat = "MM/DD/YYYY", onBlurChangeDateFormat = true, onBlurDateFormat = "DD-MMM-YYYY", onChange } = props
            return <DateSelectorCustomDisplay
                format={format}
                onFocusDateFormat={onFocusDateFormat}
                onBlurChangeDateFormat={onBlurChangeDateFormat}
                onBlurDateFormat={onBlurDateFormat}
                id={id}
                name={name}
                disabled={disabled}
                focusField={focusField}
                control={control}
                views={views}
                rules={rules}
                errors={errors[name] ? errors[name].type : ''}
                checkInternalErrors={checkInternalErrors}
                errorMessages={errorMessages}
                module={`${module}`}
                placeholder={props.placeholder}
                label={label}
                intlValues={intlValues}
                onChange={onChange}
                openTo = { openTo }
                minDate = { minDate }
            />
        }
        case "currency": {
            const { onBlur, prefix, decimalScale, inputProps } = props
            return <ControlledCurrencyTextBox
                variant="outlined"
                label={label}
                name={name}
                inputProps={inputProps}
                rules={rules}
                control={control}
                defaultValue={defaultValue}
                intlValues={intlValues}
                module={module}
                setValue={setValue}
                getValues={getValues}
                onBlur={onBlur}
                disabled={disabled}
                focusField={focusField}
                defaultText={defaultValue}
                errors={errors[name] ? errors[name].type : ''}
                errorMessages={errorMessages}
                className={className}
                prefix={prefix}
                decimalScale={decimalScale}
            />
        }
        case "number": {
            const { onBlur, prefix, decimalScale, inputProps } = props
            return <ControlledCurrencyTextBox
                variant="outlined"
                label={label}
                name={name}
                inputProps={inputProps}
                rules={rules}
                control={control}
                defaultValue={defaultValue}
                intlValues={intlValues}
                module={module}
                setValue={setValue}
                getValues={getValues}
                onBlur={onBlur}
                disabled={disabled}
                focusField={focusField}
                defaultText={defaultValue}
                errors={errors[name] ? errors[name].type : ''}
                errorMessages={errorMessages}
                className={className}
                prefix={""}
                decimalScale={0}
            />
        }
        // case 'email':
        //     return <input type="email" value={value} onChange={handleChange} />;
        default:
            return null;
    }
};

RenderInput.defaultProps = {
    type: "select",
    id: "",
    name: "",
    data: [],
    menuItemId: "",
    menuItemDisplayName: "",
    defaultVal: "",
    rules: {},
    focusField: "",
    className: "",
    menuItemDescription: "",
    errorMessages: {},
    methods: {},
    module: "",
    mode: "",
    addedMenuProps: {},
    disabled: false,
    menuItemValue: "",
    displayNameHasId: false,
    disableEmptyMenuItem: false,
    disableRequireHighlight: false,
    displayNameIdField: "",
    FormHText: "",
    loadOptions: (value: string, cb: () => void) => { cb?.() }
};

export default RenderInput;