import React from 'react';

interface HistoryItemProps {
  status: string;
  date: string;
  author: string;
  roleInfo: string;
  email: string;
  comments?: string;
}

const HistoryItem: React.FC<HistoryItemProps> = ({
  status,
  date,
  author,
  roleInfo,
  email,
  comments,
}) => {
  return (
    <div className="history-item">
      <div className="main">
        <div className="row-item">
          <div className="label">{status}</div>
          <div className="date">{date}</div>
        </div>
        <div className="author">
          {author} - {roleInfo} {email}
        </div>
      </div>
      {comments && <div className="comments">{comments}</div>}
    </div>
  );
};

export default HistoryItem;