import React from 'react';
import { ListItem, ListItemText, Typography, Box } from '@mui/material';
import '../styles/ApprovalListItem.scss';
import moment from 'moment';
import { type ApprovalObject } from 'modules/cbre-approvals-widget/types';

interface ApprovalListItemProps {
  approval: ApprovalObject;
  selectedApproval: ApprovalObject | null;
  onClick: () => void;
  style: React.CSSProperties;
}

const ApprovalListItem: React.FC<ApprovalListItemProps> = ({ approval, onClick, style, selectedApproval }) => {
  const details = approval.approvalFlow.sourceDetails
  const additionalDetails = details?.description ? details.additionalFields?.reduce((prev: any, item) => {
    prev[item.fieldName] = item;
    return prev
  }, {}) : null;
  return (
    <ListItem
      button
      onClick={onClick}
      style={style}
      className={`approval-list-item ${selectedApproval?.approvalTaskId === approval.approvalTaskId ? 'selected-list' : ''}`}
    >
      <ListItemText
        primary={<Typography component={"h4"} style={{alignSelf:"baseline"}}> {details.heading} </Typography>}
        secondary={
          additionalDetails ? <>
            <div>
              <Typography component="span" variant="body2" color="textPrimary">
                SR# {additionalDetails.wr_number?.value}
              </Typography>
              <Typography component="span" className='ml-2' variant="body2">
                • {additionalDetails.c_compid?.value}
              </Typography>
            </div>
            <div>
              <Typography component="span" variant="body2">
                Requested by {additionalDetails.requestedBy?.value} on {additionalDetails.createdAt ? moment(additionalDetails.createdAt?.value).format('MM/DD/YYYY') : ""}
              </Typography>
            </div>


          </> : null
        }
      />
      <Typography variant="body2" color="textSecondary" style={{ whiteSpace: "nowrap", alignSelf: "baseline" }}>
        Due on {details.headingRight ? moment(details.headingRight).format('MM/DD/YYYY') : ""}
      </Typography>
    </ListItem>
  );
};

export default ApprovalListItem;