import { AnalyticsBrowser } from '@segment/analytics-next';
import { shallowEqual, useSelector } from 'react-redux';

export const analytics = new AnalyticsBrowser();
const AnalyticsProvider = (props) => {
  const { children } = props;
  const { client } = useSelector(({ config }) => config, shallowEqual);
  const { uuid } = useSelector(({ auth }) => auth, shallowEqual);
  const analyticsKey = client?.segmentClientKey;
  if (!analyticsKey) {
    return children;
  }
  const sessionUUID = sessionStorage.getItem('uuid');
  analytics.load({ writeKey: analyticsKey });
  if (uuid && sessionUUID !== uuid) {
    analytics.identify(uuid, {});
    sessionStorage.setItem('uuid', uuid);
  }
  return children;
};

const Track = (eventName, properties = {}) => {
  const uuid = sessionStorage.getItem('uuid');
  const trackProperties = uuid ? { ...properties, uuid } : properties;
  analytics.track(eventName, trackProperties);
};

const PageTrack = (pageName, properties = {}) => {
  const uuid = sessionStorage.getItem('uuid');
  const trackProperties = uuid ? { ...properties, uuid } : properties;
  analytics.page(pageName, trackProperties);
};

export { AnalyticsProvider, Track, PageTrack };
