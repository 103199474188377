import React from 'react';
import { Stepper, Step, StepLabel, Typography, Box, type StepIconProps } from '@mui/material';
import { borderRadius, margin, styled } from '@mui/system';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CircleIcon from '@mui/icons-material/Circle';

interface ProgressStepperProps {
    requestStatus?: string;
}

const StyledStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepLabel-root': {
        color: theme.palette.text.secondary,
    },
    '& .MuiStepConnector-root': {
        marginLeft: 8,
    },
}));

const StyledBox = styled(Box)(({ theme }: any) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
    // maxWidth: 300,
    height: "100%"
}));

const CustomStepIcon: React.FC<StepIconProps> = (props) => {
    const { active, completed, className } = props;
    const customStyle: any = {}
    if (active) {
        return <RadioButtonCheckedOutlinedIcon style={{width: 18, height: 18, color: "#003f2d"}} />
    }else if(completed) {
        return <CircleIcon style={{width: 18, height: 18, color: "#003F2D"}}/>
     }else {
        return <RadioButtonUncheckedOutlinedIcon style={{width: 18, height: 18, color: "#bdbdbd"}}/>
    }

};

const ProgressStepper: React.FC<ProgressStepperProps> = ({ requestStatus = "Test" }) => {
    const steps = ['Submitted', 'Planning', 'Work Progress', 'Closed'];
    const currentStep = steps.indexOf("Planning");
    return (
        <StyledBox>
            <Typography variant="body1" gutterBottom>
                Request is:
            </Typography>
            <Typography variant="h6" gutterBottom>
                {requestStatus}
            </Typography>
            <StyledStepper activeStep={currentStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </StyledStepper>
        </StyledBox>
    );
};

export default ProgressStepper;