import React from 'react';
import "../styles/ApprovalChainLink.scss";
import { Divider } from '@mui/material';
import { CheckCircle, PendingActions, Person } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

interface ApproverProps {
  name: string;
  status: string;
  icon: React.ReactNode;
  type?: string;
}

interface ApprovalChainLinkProps extends ApproverProps {
  orApprover?: ApproverProps;
  approvers?: ApproverProps[];
}

const ApprovalChainLink: React.FC<ApprovalChainLinkProps> = ({ approvers, type }) => {

  const renderIcon = (type: string) => {
    if(type?.toLowerCase() === "approved") return <CheckCircle style={{color: '#50AF78'}}/>
    if(type?.toLowerCase() === "pending") return <CircleOutlinedIcon style={{color: '#FF9742'}}/>
    return <CircleOutlinedIcon style={{color: '#E7E7F9'}}/>
  }

  const containerBorder = (type?: string) => {
    if(type?.toLowerCase() === "approved") return 'border-green'
    if(type?.toLowerCase() === "pending") return 'border-orange'
    return 'border-grey'
  }

  return (
    <div className={`approval-chain-link ${containerBorder(type)}`}>
      <div className="approval-sequence">

        {approvers && approvers.map((approver, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <Divider className='my-3'> {type?.toUpperCase()} </Divider>}
            <div className="approval-row">
              <div className="approval-inner">
                <div className="icon-wrap">{approver.icon}</div>
                <div className="lv-set">
                  <div className="value">{approver.name}</div>
                  <div className="value">{approver.status}</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ApprovalChainLink;