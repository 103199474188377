import { type Approver } from "../types";
import mockResponse from "./api";


export default function useApprovalChain() {
  const groupApprovers = (approvers: Approver[]) => {
    const grouped = approvers.reduce((acc, approver) => {
      const { group } = approver;
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(approver);
      return acc;
    }, {} as Record<number, Approver[]>);

    // Convert the grouped object to an array and sort each group by sequence
    return Object.keys(grouped).map(group => ({
      group: Number(group),
      approvers: grouped[Number(group)].sort((a, b) => a.sequence - b.sequence)
    }));
  };

  const groupedApprovers = groupApprovers(mockResponse.factObjectSubState.chains[0].approvers);

  return { groupedApprovers };
}