/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useEffect } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import { useSelector } from 'react-redux';
import { useTreatments, SplitContext } from '@splitsoftware/splitio-react';
import Navigation from '../../components/Navigation';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Badge } from '@mui/material';
import { useApprovalsCount } from 'modules/cbre-approvals-widget';

const ApprovalBadge = ({ disabled }) => {
  const url = useSelector((state) => state.config.client.flowApiUrl + 'api');
  const { myApprovalCount } = useApprovalsCount({ url })

  return (
    <Badge badgeContent={myApprovalCount || 0} className='approval-icon' color="secondary" max={99}>
      <AssignmentTurnedInIcon />
    </Badge>
  );
};
// eslint-disable-next-line complexity
const SideBarContent = () => {
  const appConfig = useSelector(state => state.config);
  const { permissions, isSystemAdmin } = useSelector(state => state.auth);
  const tileFeatureName = 'PJM_PL_SHOW_TILE';
  // access status properties via the SplitContext
  const { isReady } = useContext(SplitContext);
  // evaluate feature flags with the `useTreatments` hook
  const treatments = useTreatments([tileFeatureName]);
  const { treatment, config: treatmentConfig } = treatments[tileFeatureName];
  const defaultConfig = {
    showPrincipalLite: true,
    maxWidth: 57,
    columns: { desktop: 4, tablet: 3, mobile: 1 }
  };

  const config = isReady ? JSON.parse(treatmentConfig || {}) : defaultConfig;
  const navigationMenus = [
    /* {
      name: 'sidebar.menu',
      type: 'section',
      children: [ */
    {
      name: 'sidebar.dashboard',
      type: 'item',
      isExternalURL: false,
      disabled: false,
      link: '/elis/dashboard',
      customIcon: 'dashboard-icon'
    },
    {
      name: 'sidebar.instruments',
      type: 'item',
      link: '/elis/instruments',
      disabled: false,
      customIcon: 'lab-instrument-icon'
    },
    {
      name: 'sidebar.requests',
      type: 'item',
      link: '/elis/requests',
      disabled: false,
      customIcon: 'request-icon'
    },
  ];
  if (isSystemAdmin || permissions.isSystemAdmin || permissions?.level?.includes(1)) {
    const children = [];
    if (permissions.isSystemAdmin) {
      children.push(
        {
          name: 'pages.CustomFieldsMgmt',
          type: 'item',
          link: '/CustomFieldsMgmt',
          customIcon: 'sideMenuCustomIcons backgroundImageCustomFieldMgmt',
          isDivider: false,
          isbottom: false
        },
        {
          name: 'pages.approvalConfiguration',
          type: 'item',
          link: '/ApprovalConfiguration',
          customIcon:
            'sideMenuCustomIcons approval-config-icon-custom-width backgroundImageApprovalConfiguration'
        }
      );
    }
    children.push({
      name: 'logo.dataAdmin',
      type: 'item',
      link: '/dataadministration',
      customIcon: 'sideMenuCustomIcons backgroundImageDataAdmins'
    });
    navigationMenus.push(
      {
        name: 'sidebar.approvals',
        type: 'item',
        link: '/elis/approvals',
        disabled: false,
        RenderIcon: ApprovalBadge
      },
      {
        name: 'sidebar.administration',
        type: 'item',
        link: '/administration/user-management',
        // defaultExpanded: true,
        // icon: 'backgroundImageOkay2Pay-sm',
        customIcon: 'dashboard-icon',
        isbottom: false
        // children
      }
    );
  }
  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};
export default SideBarContent;
