export const languageData = [
  {
    languageId: 'english',
    locale: 'en_US',
    name: 'English US',
    icon: 'us',
  },
  {
    languageId: 'chinese',
    locale: 'zh_CN',
    name: 'Chinese Simplified',
    icon: 'cn',
  },
  {
    languageId: 'english-uk',
    locale: 'en_GB',
    name: 'English UK',
    icon: 'gb',
  },
  {
    languageId: 'french',
    locale: 'fr_FR',
    name: 'French',
    icon: 'fr',
  },
  {
    languageId: 'dutch-belgium',
    locale: 'nl_BE',
    name: 'Dutch Belgium',
    icon: 'nl',
  },
  {
    languageId: 'dutch-netherlands',
    locale: 'nl_NL',
    name: 'Dutch Netherlands',
    icon: 'nl',
  },
  {
    languageId: 'german',
    locale: 'de_DE',
    name: 'German',
    icon: 'de',
  },
  {
    languageId: 'hebrew',
    locale: 'he_IL',
    name: 'Hebrew',
    icon: 'he',
  },
  {
    languageId: 'italian',
    locale: 'it_IT',
    name: 'Italian',
    icon: 'it',
  },
  {
    languageId: 'japanese',
    locale: 'ja_JP',
    name: 'Japanese',
    icon: 'ja',
  },
  {
    languageId: 'polish',
    locale: 'pl_PL',
    name: 'Polish',
    icon: 'pl',
  },
  {
    languageId: 'spanish',
    locale: 'es_ES',
    name: 'Spanish',
    icon: 'es',
  },
  {
    languageId: 'swedish',
    locale: 'sv_SE',
    name: 'Swedish',
    icon: 'sv',
  },
  {
    languageId: 'portuguese-portugal',
    locale: 'pt_PT',
    name: 'Portuguese Portugal',
    icon: 'pt',
  },
  {
    languageId: 'portuguese-brazil',
    locale: 'pt_BR',
    name: 'Portuguese Brazil',
    icon: 'br',
  },
  {
    languageId: 'spanish-latin-america',
    locale: 'es_LA',
    name: 'Spanish Latin America',
    icon: 'es',
  },
  {
    languageId: 'french-canada',
    locale: 'fr_CA',
    name: 'French Canada',
    icon: 'ca',
  },
  {
    languageId: 'korean-south-korea',
    locale: 'ko_KR',
    name: 'Korean South Korea',
    icon: 'kr',
  },
  {
    languageId: 'thai',
    locale: 'th_TH',
    name: 'Thai',
    icon: 'th',
  },
  {
    languageId: 'vietnamese',
    locale: 'vi_VN',
    name: 'Vietnamese',
    icon: 'vi',
  },
];

export const defaultLang = {
  languageId: 'english',
  locale: 'en_US',
  name: 'English',
  icon: 'us',
};
