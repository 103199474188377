import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';

export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 0,
      tablet: 600,
      laptop: 960,
      desktop: 1280,
    },
  },
  palette: {
    primary: {
      light: green[300],
      main: green[500],
      dark: green[700],
      contrastText: '#fff',
    },
    secondary: {
      light: orange[300],
      main: orange.A700,
      dark: orange[700],
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
  },
};
