import { useState, useEffect, useRef, useMemo } from 'react';
import service from 'util/apiService';
import {
  type ReturnUseApprovalsCount,
  type ApprovalsCount,
  type ReturnUseApprovalsListApiProps,
  type UseApprovalsListApiProps,
  type UseApprovalsCountProps,
} from '../types';

const useApprovalsCount = <T>({
  url: baseUrl,
  queryParams = {},
  payload = null,
  loadApiOnMount,
  broadCastName = ''
}: UseApprovalsCountProps<T>): ReturnUseApprovalsCount => {
  const [approvalsCount, setApprovalsCount] = useState<ApprovalsCount>({
    myApprovalCount: 0,
    externalApprovalCount: 0,
    historyCount: 0,
  });
  const approvalsCountBroadCast = useRef(new BroadcastChannel(`approvalsCount_${broadCastName}`)).current
  const [url, setUrl] = useState<string>(baseUrl);

  const fetchApprovalsCount = async () => {
    try {
      const { data = {}} = await service.get(`${url}/approvals/counts`);
    //   setApprovalsCount(response.data);
    const resp = {...approvalsCount, ...data}
      approvalsCountBroadCast.postMessage(resp);
      setApprovalsCount(resp);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    approvalsCountBroadCast.onmessage = ({data}: MessageEvent<ApprovalsCount>) => {
      setApprovalsCount(()=> ({...data}));
    };
  }, [approvalsCountBroadCast, setApprovalsCount]);

  useEffect(() => {
     fetchApprovalsCount()
  }, [url]);

  return { ...approvalsCount, fetchApprovalsCount };
};

export default useApprovalsCount;
