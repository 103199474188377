/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';

const rootEl = document.querySelector('#pjm-root');

// Create a reusable render method that we can call more than once
const render = () => {
  // Dynamically import our main App component, and render it
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const MainApp = require('./MainApp').default;
  window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  ReactDOM.render(<MainApp />, rootEl);
};

render();
